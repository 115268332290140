import { Grid, Typography } from "@mui/material";
import { ContactNumberInput, EmailInput } from "raci-react-library";
import { FormProvider, UseFormReturn } from "react-hook-form";
import ConfirmDetailsButton from "../shared/components/ConfirmDetailsButton";
import { GetMemberDetailsResponse } from "../shared/hooks/useApiClient/ClientProxy.generated";
import { StyledConfirmDetailsButtonContainer, StyledContactNumberInputContainer, StyledSubtext } from "./styled";
import { ConfirmDetailsState } from "./types";

export interface FormProps extends GetMemberDetailsResponse {
  form: UseFormReturn<ConfirmDetailsState>;
  onSubmit: (newValues: ConfirmDetailsState) => Promise<void>;
  loaded: boolean;
  detailsUpdated?: boolean;
  redirectToNextPage?: () => void;
}

export const Form: React.FC<FormProps> = ({
  firstName,
  form,
  onSubmit,
  loaded,
  detailsUpdated,
  redirectToNextPage,
  email,
}) => {
  const { handleSubmit } = form;

  return loaded ? (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#" data-testid="contactDetailsForm" autoComplete="on" noValidate>
        <Grid container direction="column">
          <Grid item xs sx={{ marginBottom: "24px" }}>
            <Typography variant="h2">{firstName}, please confirm your contact details</Typography>
          </Grid>
          <Grid item xs>
            <StyledSubtext variant="body1">We'll update your contact details if you change them.</StyledSubtext>
          </Grid>
        </Grid>

        <Grid container direction="column">
          <StyledContactNumberInputContainer item xs>
            <ContactNumberInput isMobile={false} sublabel="Mobile preferred so we can SMS you." />
          </StyledContactNumberInputContainer>
          <Grid item xs>
            <EmailInput sublabel="If you change this, it won't affect your login email." defaultValue={email} />
          </Grid>

          <StyledConfirmDetailsButtonContainer item xs>
            <ConfirmDetailsButton
              submitted={detailsUpdated !== undefined}
              detailsUpdated={detailsUpdated}
              afterAnimationRedirect={redirectToNextPage}
            />
          </StyledConfirmDetailsButtonContainer>
        </Grid>
      </form>
    </FormProvider>
  ) : null;
};
