import { StyledEngineProvider } from "@mui/material";
import { unstable_ClassNameGenerator as ClassNameGenerator } from "@mui/material/utils";
import { RacwaAuth } from "@racwa/react-adb2c";
import { RacwaThemeProvider } from "@racwa/react-components";
import { SessionStatePrefixProvider } from "raci-react-library";
import { RecoilRoot } from "recoil";
import { ADB2C_CLIENT_ID, ADB2C_DOMAIN, ADB2C_TENANT } from "./shared/constants";
import View from "./view";
import { SESSION_KEY_PREFIX } from "./view/constants";

ClassNameGenerator.configure((componentName) => `confirmdetails-mfe-${componentName}`);

export const App: React.FC = () => (
  <RacwaAuth clientId={ADB2C_CLIENT_ID} domain={ADB2C_DOMAIN} tenant={ADB2C_TENANT}>
    <StyledEngineProvider injectFirst={true}>
      <RacwaThemeProvider>
        <RecoilRoot>
          <SessionStatePrefixProvider prefix={SESSION_KEY_PREFIX}>
            <View />
          </SessionStatePrefixProvider>
        </RecoilRoot>
      </RacwaThemeProvider>
    </StyledEngineProvider>
  </RacwaAuth>
);

export default App;
