import { useSessionState } from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import useApiClient from "../../shared/hooks/useApiClient";
import {
  GetMemberDetailsResponse,
  UpdateMemberDetailsRequest,
} from "../../shared/hooks/useApiClient/ClientProxy.generated";
import { ConfirmDetailsState, MFEAction } from "../types";

export interface UseConfirmDetailsReturn extends GetMemberDetailsResponse {
  form: UseFormReturn<ConfirmDetailsState>;
  onSubmit: (newValues: ConfirmDetailsState) => Promise<void>;
  loaded: boolean;
  detailsUpdated?: boolean;
  redirectToNextPage?: () => void;
}

enum LoadingState {
  NotLoaded,
  Loading,
  Loaded,
}

export const useConfirmDetails = (): UseConfirmDetailsReturn => {
  const apiClient = useApiClient();
  const [detailsUpdated, setDetailsUpdated] = useState<boolean | undefined>(undefined);
  const [sessionState, setSessionState] = useSessionState<ConfirmDetailsState>();
  const form = useForm<ConfirmDetailsState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: sessionState,
  });
  const { setValue } = form;
  const [loadingState, setLoadingState] = useState(LoadingState.NotLoaded);

  const setBackdrop = (activate: boolean) =>
    window.microFrontendCallBackConfirmDetails?.(activate ? MFEAction.TurnOnBackDrop : MFEAction.TurnOffBackDrop);

  useEffect(() => {
    const get = async () => {
      try {
        setBackdrop(true);
        setLoadingState(LoadingState.Loading);
        const response = await apiClient.getmemberdetails();
        setSessionState(response.result);
        setValue("email", response.result.email);
        setValue("contactNumber", response.result.contactNumber);
      } catch {
        window.microFrontendCallBackConfirmDetails?.(MFEAction.Error);
      } finally {
        setBackdrop(false);
        setLoadingState(LoadingState.Loaded);
      }
    };

    // we want to make sure the MFE is initialised fully before fetching
    // specifically the callback
    if (window.microFrontendCallBackConfirmDetails && loadingState !== LoadingState.Loading) {
      get();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.microFrontendCallBackConfirmDetails]);

  const redirectToNextPage = () => {
    window.microFrontendCallBackConfirmDetails?.(MFEAction.Submit, sessionState);
  };

  const onSubmit = async (newValues: ConfirmDetailsState) => {
    try {
      setBackdrop(true);
      const hasChanged =
        newValues.contactNumber !== sessionState.contactNumber || newValues.email !== sessionState.email;

      if (hasChanged) {
        const request: UpdateMemberDetailsRequest = {
          contactNumber: newValues.contactNumber?.replaceAll(/\s/g, ""),
          email: newValues.email,
        };
        await apiClient.updatememberdetails(request);
        setSessionState({ ...sessionState, ...newValues });
        setDetailsUpdated(true);
      } else {
        setDetailsUpdated(false);
      }
    } catch {
      window.microFrontendCallBackConfirmDetails?.(MFEAction.Error);
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
    loaded: loadingState === LoadingState.Loaded,
    detailsUpdated,
    redirectToNextPage,
    ...sessionState,
  };
};

export default useConfirmDetails;
