import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonProps } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { RacwaNotifyButton } from "@racwa/react-components";
import { ButtonTextChangeTimeout } from "raci-react-library";
import { useEffect } from "react";

export interface ConfirmDetailsButtonProps extends ButtonProps {
  submitted?: boolean | null;
  detailsUpdated?: boolean | null;
  afterAnimationRedirect?: () => void;
}

export const ConfirmDetailsButton: React.FC<ConfirmDetailsButtonProps> = ({
  submitted,
  detailsUpdated,
  afterAnimationRedirect,
  disabled,
}) => {
  const messageText = detailsUpdated ? "Details updated" : "Details confirmed";

  const buttonStates = [
    {
      children: "Confirm",
      color: "primary",
      fullWidth: true,
      disabled,
      type: "submit",
      "data-testid": "submit",
    },
    {
      children: messageText,
      color: "secondary",
      fullWidth: true,
      startIcon: <FontAwesomeIcon icon={faCheck} aria-hidden />,
      "data-testid": "member-details-confirmed",
    },
  ];

  useEffect(() => {
    if (submitted) {
      setTimeout(() => afterAnimationRedirect?.(), ButtonTextChangeTimeout);
      gtm(event(messageText));
    }
  }, [submitted, afterAnimationRedirect, messageText]);

  return (
    <RacwaNotifyButton
      activeState={submitted ? 1 : 0}
      // The buttonStates forward all of their props to the underlying button
      // but are strictly typed to only allow specific props,
      // this makes it "impossible" to set things like type and testid
      buttonStates={buttonStates as React.ComponentProps<typeof RacwaNotifyButton>["buttonStates"]}
    />
  );
};

export default ConfirmDetailsButton;
