import { useMsal } from "@azure/msal-react";
import { useAccount } from "@racwa/react-adb2c";
import { useMemo } from "react";
import { ADB2C_CLIENT_ID } from "../../constants";
import { ApiClient } from "./ClientProxy.generated";

let useApiClient: () => ApiClient;

const useRealApiClient = () => {
  const { instance: adb2cInstance } = useMsal();
  const adb2cAccount = useAccount({
    clientId: ADB2C_CLIENT_ID,
  });

  return useMemo(() => {
    const fetchWrapper = async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
      let adb2cToken = "";
      const headers: any = { ...init?.headers };
      const loginRequestBase = {
        scopes: [],
        redirectUri: window.location.pathname,
      };

      const adb2cLoginRequest = {
        ...loginRequestBase,
        account: adb2cAccount,
      };

      try {
        const tokenResponse = await adb2cInstance.acquireTokenSilent(adb2cLoginRequest);
        adb2cToken = tokenResponse.idToken;
      } catch {
        await adb2cInstance.acquireTokenRedirect(adb2cLoginRequest);
      }

      if (adb2cToken) {
        headers["Authorization"] = `Bearer ${adb2cToken}`;
      }

      const response = await fetch(url, {
        ...init,
        headers,
      });
      return response;
    };

    return new ApiClient(process.env.REACT_APP_API_BASE_URL || "", {
      fetch: fetchWrapper,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
/*
 ** WARNING: KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO
 ** TO THE BELOW IF STATEMENT.
 ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN
 ** THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET
 ** ENVIRONMENT
 ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE
 ** AND SHIP CODE (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED
 ** JAVASCRIPT ARTIFACTS) FOR MICROSOFT AUTHENTICATION LIBRARY
 */
if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT"
) {
  useApiClient = require("../../../__NPE__/useApiClient").default;
} else if (process.env.REACT_APP_ENVIRONMENT === "UAT") {
  const { BypassService } = require("raci-react-library-npe");
  // Need to use NPE client to set RACWA-CRM-ID header if 'logged in' via test launch page
  useApiClient = BypassService.isUsingTestLaunchPage()
    ? require("../../../__NPE__/useApiClient").default
    : useRealApiClient;
} else {
  useApiClient = useRealApiClient;
}

export default useApiClient;
