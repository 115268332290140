import { Grid, styled, Typography } from "@mui/material";
import { colors } from "@racwa/styles";

export const StyledSubtext = styled(Typography)({
  fontWeight: 400,
  color: colors.dieselDeeper,
});

export const StyledContactNumberInputContainer = styled(Grid)({
  marginTop: "8px",
});

export const StyledConfirmDetailsButtonContainer = styled(Grid)({
  margin: "24px 0 32px 0",
});
