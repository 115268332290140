import { SessionState } from "raci-react-library";
import { GetMemberDetailsResponse } from "../shared/hooks/useApiClient/ClientProxy.generated";

export enum MFEAction {
  Error = "Error",
  Submit = "Submit",
  TurnOnBackDrop = "TurnOnBackDrop",
  TurnOffBackDrop = "TurnOffBackDrop",
}

export interface ConfirmDetailsState extends SessionState, GetMemberDetailsResponse {}
