import { Form } from "./Form";
import useConfirmDetails from "./hooks/useConfirmDetails";

const View: React.FC = () => {
  const props = useConfirmDetails();

  return <Form {...props} />;
};

export default View;
